<template>
  <v-container fluid>
    <v-dialog
      :value="true"
      persistent
      max-width="600"
      transition="dialog-transition"
      overlay-color="black"
      overlay-opacity="0.1"
      @input="$emit('close-dialog')"
    >
      <v-card>
        <v-form style="min-height: 440px;">
          <v-card-title style="min-height: 120px;">
            <v-list-item class="d-flex flex-row justify-space-between">
              <v-row class="d-flex flex-row justify-start align-center">
                <avatar-field
                  v-model="form.avatar"
                  :error-messages="!form.avatar ? $t('common.error') : ''"
                  :active="true"
                  custom-size="48"
                  custom-icon="publish"
                />
                <span class="ml-4">{{ $t('titles.dataOf', [$t('common.staff')] ) }}</span>
              </v-row>
              <v-list-item-action>
                <v-btn icon color="black" @click="$emit('close-dialog')">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-main class="px-6 py-0">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.firstName"
                  :error-messages="getFieldErrors('firstName')"
                  :label="$t('user.fields.firstName')"
                  outlined
                  dense
                  @blur="$v.form.firstName.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.lastName"
                  :error-messages="getFieldErrors('lastName')"
                  :label="$t('user.fields.lastName')"
                  outlined
                  dense
                  @blur="$v.form.lastName.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.job"
                  :error-messages="getFieldErrors('job')"
                  :label="$t('user.fields.job')"
                  outlined
                  dense
                  @blur="$v.form.job.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.dni"
                  :label="`${$t('user.fields.numberIdentification')}  ${$t('common.optional')}`"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.email"
                  :error-messages="getFieldErrors('email')"
                  :label="$t('user.fields.email')"
                  outlined
                  dense
                  @blur="$v.form.email.$touch()"
                />
              </v-col>
              <v-col cols="6">
                <phone-field
                  v-model="form.phone" outlined dense
                  :error-messages="getFieldErrors(`phone.code`) || getFieldErrors(`phone.number`)"
                  @blur="$v.form.phone.$touch()"
                />
              </v-col>
            </v-row>
          </v-main>
          <v-card-actions style="height: 100%;" class="d-flex flex-row justify-center align-center ma-0">
            <v-btn
              color="primary"
              width="10rem"
              type="submit"
              :disabled="$v.form.$invalid"
              depressed
              @click="(!$v.form.$invalid && !$v.form.$error) && $emit(isEditing ? 'update': 'create', form);
                      (!$v.form.$invalid && !$v.form.$error) && $emit('close-dialog')"
              v-text="$t('common.save')"
            />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'StaffFormDialog',
  components: {
    AvatarField: () => import('@/components/AvatarField'),
    PhoneField: () => import('@/components/formFields/PhoneField'),
  },
  mixins: [formUtils],
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {},
    }
  },
  validations() {
    return {
      form: {
        avatar: {},
        firstName: { required },
        lastName: { required },
        job: { required },
        email: { required, email },
        phone: { code: { required }, number: { required } },
      },
    }
  },
  computed: {
    isEditing() {
      return !!this.staff
    },
  },
  created() {
    this.form = {
      ...this.staff,
    }
  },
}
</script>
